(function ($) { 
 
/**********   START GRID.JS *****/
  var type_labels = {
    'marketing_site': 'PagerDuty Website',
    'blog': 'PagerDuty Blog',
    'company_blog': 'Company Blog',
    'community': 'Community',
    // 'knowledge_base': 'Knowledge Base (Swiftype)',
    'integration_guide': 'Integration Guides',
    'ext_api_reference': 'API Reference',
    'ext_docs': 'Developer Docs',
    'ext_kb': 'Knowledge Base',
  }

  var customResultRenderFunction = function(ctx, data) {
    var resultSets = {};
    $.each(type_labels, function(type, label) {
      resultSets[type] = [];
    });

    $.each(data, function(idx, result) {
      if(type_labels[result.type] && resultSets[result.type].length < 4) {
        resultSets[result.type].push(result);
      }
    });

    $.each(resultSets, function(type, results) {
      if(results.length > 0){
        var column = $("<div class='grid-result-column'><h3>" + type_labels[type] + "</h3></div>");
        column.appendTo(ctx.list);
        $.each(results, function(idx, item) {
          ctx.registerResult($("<div class='result'>" + item.title + "</div>").appendTo(column), item);
        });
      } 
    });
  };

  $('#search-input').swiftype({
    engineKey: 'vkA2nKGB3k3u6x4XGxpN',
    resultRenderFunction: customResultRenderFunction,
    suggestionListType: 'div',
    resultListSelector: '.result',
    resultLimit: 40,
    typingDelay: 0,
    fetchFields: { 'page': ['title', 'url', 'type'] }
  });

})(jQuery);
